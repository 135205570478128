<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-card>
      <v-toolbar flat color="white" class="mt-3">
        <v-toolbar-title>Descargar</v-toolbar-title>
        <v-divider class="mx-4" inset vertical> </v-divider>
        <v-spacer></v-spacer>

        <v-menu v-model="menu" :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="rangoFechas"
              label="Fechas"
              persistent-hint
              readonly
              append-icon="mdi-calendar-multiple"
              v-bind="attrs"
              v-on="on"
              :clearable="true"
              @change="cargarReporte()"
            ></v-text-field>
          </template>
          <v-date-picker
            range
            v-model="searchFechas"
            no-title
            @change="menu = false"
            locale="es-es"
          >
          </v-date-picker>
        </v-menu>

        <v-select
          :items="listaEstab"
          label="Establecimiento"
          dense
          item-text="store_name"
          item-value="product_store_id"
          v-model="searchEstab"
          append-icon="mdi-magnify"
          class="mt-1 mx-4"
          return-object
          @change="cargarReporte()"
          flat
        ></v-select>
      </v-toolbar>
      <v-toolbar flat color="white">
        <v-btn
          color="primary"
          small
          class="ma-2 white--text"
          @click="exportar()"
        >
          <v-icon left>mdi-microsoft-excel</v-icon>
          Exportar
        </v-btn>
      </v-toolbar>

      <v-data-table
        :headers="headersLista"
        :items="lista"
        :search="search"
        :loading="loadingTable"
        :disable-sort="$vuetify.breakpoint.name == 'xs'"
        :items-per-page="15"
        :options.sync="options"
        loading-text="Cargando datos..."
        no-results-text="No se han encontrado datos"
        no-data-text="Sin datos"
        class="elevation-1"
        dense
      >
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";

export default {
  name: "ReporteCloseup",
  // invoice_item_type_id
  // numero_factura
  // fecha_factura
  // product_id
  // laboratorio
  // producto
  // cantidad
  // precio_desc
  // total
  // pvp
  // medico
  // cedula_medico
  // especialidad
  // direccion
  // ciudad
  // establecimiento
  // categoria
  data: () => ({
    headersLista: [
      { text: "Código", value: "product_id" },
      { text: "Num. Fa", value: "numero_factura" },
      { text: "Fecha. Fa", value: "fecha_factura" },
      { text: "Nombre de producto", value: "producto" },
      { text: "Laboratorio", value: "laboratorio" },
      { text: "Precio", value: "precio_desc" },
      { text: "Total", value: "total" },
      { text: "PVP", value: "pvp" },
      { text: "Medico", value: "medico" },
    ],
    search: "",

    listaEstab: [],
    lista: [],
    overlay: false,
    options: {},
    dialog: false,
    notifications: false,
    sound: true,
    widgets: true,
    searchFechas: [],
    searchEstab: "",
    menu: false,
    filters: [
      {
        cols: 6,
        class: "py-0 py-md-2 col-md-2 pr-0 pr-sm-2",
        v_model: [],
        label: "Fecha",
        type: "input_date",
        clearable: true,
      },
      {
        cols: 6,
        class: "py-0 py-md-2 col-md-2 pl-0 pl-sm-2",
        v_model: "",
        label: "Bodega",
        type: "input_select",
        text: "tipoNombre",
        value: "tipoId",
        clearable: true,
        multiple: true,
        items: [],
      },
    ],
  }),
  computed: {
    ...mapState("master", ["loadingTable", "user", "tenantId"]),

    ...mapGetters("access", ["btnAbrirCaja"]),

    rangoFechas() {
      return this.searchFechas.join(" ~ ");
    },
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setMenu",
      "setTitleToolbar",
      "setLoadingTable",
    ]),

    ...mapActions("master", ["requestApi", "alertNotification"]),

    exportar() {
      this.overlay = true;

      if (this.searchFechas[0] == null) {
        this.overlay = false;
        //alert('Seleccione una fecha');
        this.alertNotification({
          param: {
            html: "Seleccione una fecha.",
            timer: 10000,
            title: "Error",
            icon: "mdi-cancel",
            confirmButtonColor: "red",
          },
        });

        return false;
      }

      if (this.searchEstab == null) {
        this.overlay = false;
        //alert('Seleccione un establecimiento');
        this.alertNotification({
          param: {
            html: "Seleccione un establecimiento.",
            timer: 10000,
            title: "Error",
            icon: "mdi-cancel",
            confirmButtonColor: "red",
          },
        });
        return false;
      }

      this.setUrl("reportes");
      this.requestApi({
        method: "POST",
        data: {
          accion: "reporteCloseupExportar",
          desde: this.searchFechas[0],
          hasta: this.searchFechas[1],
          estab: this.searchEstab.codigo_establecimiento,
        },
      })
        .then((res) => {
          //console.log(res.data);

          var a = document.createElement("a");
          a.href =
            "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
            res.data.detail.datos;
          a.download = res.data.detail.archivo;
          a.click();
          this.overlay = false;
        })
        .then(() => {});
    },

    cargarReporte() {
      console.log(this.searchEstab.codigo_establecimiento);

      this.setUrl("reportes");
      this.requestApi({
        method: "POST",
        data: {
          accion: "reporteCloseup",
          desde: this.searchFechas[0],
          hasta: this.searchFechas[1],
          estab: this.searchEstab.codigo_establecimiento,
        },
      })
        .then((res) => {
          this.lista = res.data.detail;
        })
        .then(() => {});
    },

    cargarEstablecimientos() {
      this.setUrl("establecimiento");
      this.requestApi({
        method: "GET",
        data: {},
      })
        .then((res) => {
          this.listaEstab = res.data._embedded.establecimiento;
        })
        .then(() => {});
    },
  },

  mounted() {
    this.cargarEstablecimientos();
    this.cargarReporte();
    this.setTitleToolbar("REPORTE CLOSEUP");
  },

  watch: {},
};
</script>
